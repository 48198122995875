<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="rOrderdetail-dlg-bg" flat>
            <q-card-section class="q-pa-sm">
                <div class="q-ma-lg row">
                    <div class="topleftblue q-mr-md"></div>订单详情
                </div>
                <div class="bg-grey-11 top-orderInfo row">
                    <div class="col column">
                        <p>出库单号：--</p>
                        <p>{{ OrderInfo.UserName }} {{ OrderInfo.TelPhone }} {{ OrderInfo.Address }}</p>
                    </div>
                    <div class="col">
                        <p>处方数量：{{ CFList != null ? CFList.length : 0 }} </p>
                        <p>购药总数量：{{ GoodsCount }}件</p>
                    </div>
                    <div class="col">
                        病情描述：{{ OrderInfo.PatnSympDesc }}
                    </div>
                </div>
                <q-splitter v-model="splitterModel" rounded class="row" style="border:1px solid rgba(0, 0, 0, 0.156)">

                    <template v-slot:before class="col-1">
                        <q-tabs v-model="tab" vertical active-class="sel_cf" class="text-primary">
                            <q-tab v-for="(cf, index) in CFList" :key="index" :name="cf.MZCFID" :label="cf.CFTitle" />
                        </q-tabs>
                    </template>
                    <template v-slot:after class="col-10">
                        <div class="q-pa-md">
                            <div class="row">
                                <p class="text-bold">{{ Sel_CF == null ? "" : Sel_CF.CFTitle }}</p>
                                <p class="q-ml-lg">药物数量：<span class="text-bold">{{ Sel_CF == null ? 0 :
        Sel_CF.GoodsCount
}}件</span></p>
                                <p class="q-ml-lg">总费用：<span class="text-bold">{{ Sel_CF == null ? 0 : Sel_CF.TotalAmt
}}元</span></p>
                            </div>

                            <vxe-grid show-overflow ref="rgItemTable" row-id="GroupID" v-bind="gridOptions"
                                :sort-config="{ multiple: true }" :custom-config="{ storage: true }" id="custom-config"
                                :export-config="gridOptions.tableExport"
                                :max-height="$store.getters.maxPageHeight - 315" :loading="Loading"
                                highlight-current-row size="mini">
                                <!-- 
                                <template #img="{ row }">
                                    <img :src="row.GoodsImgUrl" style="width: 113px;height: 113px;">
                                </template> -->

                                <template #miTags="{ row }">
                                    <span class="q-ma-xs" v-for="(miTag, index) in row.MITags" :key="index">{{
        miTag.Name
}}</span>
                                </template>
                            </vxe-grid>
                        </div>

                    </template>

                </q-splitter>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn class="cancel-btn" label="关闭" @click="onCancelClick" />
                <!-- <q-btn v-if="IsAll != 1" class="ok-btn" label='保存' @click="onOKClick" /> -->
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
  
<script>
import { useQuasar } from 'quasar'
import { mapActions, mapState } from "vuex";


export default {
    components: {
    },
    computed: {
        ...mapState("PdmRecvOrder", [
            "Loading",
        ]),
    },
    props: {
        OrderInfo: {
            default: null,
            type: Object
        },
        OrderID: {
            default: "0",
            type: String
        },
        IsEdit: {
            default: 0,
            type: Number
        }

    },
    emits: [
        'ok', 'hide'
    ],
    watch: {
        tab: {
            handler(val) {
                this.Sel_CF = this.CFList.filter(x => x.MZCFID == val)[0]
                this.gridOptions.data = this.Sel_CF.CFGoodsList;
            },
        },
    },
    data() {
        return {
            // dialog: null,
            $q: useQuasar(),
            tab: 'mails',
            splitterModel: 15,
            FilterString: "",
            CFList: [],
            allAlign: null,
            Sel_GoodsIDs: [], //table选中的GoodsIDs
            Sel_CF: null,
            GoodsCount: 0,
            gridOptions: {
                border: true,
                resizable: true,
                showOverflow: true,
                highlightHoverRow: true,
                align: "left",
                tableExport: {},
                columns: [
                    // { title: "Img", slots: { default: 'img' }, sortable: false, align: "center", width: 160 },
                    { field: "GoodsHisCode", title: "商品His编码", sortable: true, align: "center", width: 160, },
                    { field: "GoodsName", title: "商品名称", sortable: true, align: "center", width: 160, },
                    { field: "GoodsSpec", title: "商品规格", sortable: true, align: "center", width: 100, },
                    { field: "DrugDose", title: "每次剂量", sortable: true, align: "center", width: 100, },
                    { field: "GoodsUnit", title: "商品单位", sortable: true, align: "center", width: 100, },
                    { field: "DrugWayName", title: "用法", sortable: false, align: "center", width: 100, },
                    { field: "DrugMedcDays", title: "用药天数", sortable: true, align: "center", width: 100, },
                    { field: "CNT", title: "数量", sortable: true, align: "center", width: 100, },
                    { field: "Pric", title: "单价", sortable: true, align: "center", width: 100, },
                    { field: "SumAmt", title: "总金额", sortable: true, align: "center", width: 100, },
                    // { field: "GoodsImgPath", title: "商品图片路径", sortable: true, align: "center", width: 160, },
                    { field: "MITags", slots: { default: 'miTags' }, title: "医保标签", sortable: false, align: "center", width: 120, },
                    { field: "PinYin", title: "拼音", sortable: true, align: "center", width: 100, }],
                data: null,
            },
        };
    },
    mounted() {
        this.onLoadData()
    },
    methods: {
        ...mapActions("PdmRecvOrder", [
            "actGetMZCFList"
        ]),
        show() {
            this.$refs.dialog.show()
        },
        hide() {
            this.$refs.dialog.hide()
        },
        onDialogHide() {
            // QDialog发出“hide”事件时
            // 需要发出
            this.$emit('hide')
        },
        onCancelClick() {
            // 我们只需要隐藏对话框
            this.hide()
        },
        onOKClick() {
            this.$emit('ok', this.Sel_GoodsIDs)

            this.hide()
        },
        onLoadData() {
            this.actGetMZCFList({
                HospID: this.$store.getters["appUser/hospID"],
                CUser: this.$store.getters["appUser/userID"],
                OrderID: this.OrderID
            }).then(res => {
                if (res.Code == 0) {
                    this.CFList = res.Data;
                    this.GoodsCount = res.OtherData
                    if (this.CFList.length > 0) {
                        this.Sel_CF = this.CFList[0]
                        this.tab = this.CFList[0].MZCFID
                        this.gridOptions.data = this.CFList[0].CFOrderlist;
                    }
                }
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.onLoadData();
        }

    },
};
</script>
  
<style scoped>
.rOrderdetail-dlg-bg {
    position: absolute;
    min-width: 65%;
    min-height: 70%px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
}

.rOrderdetail-dlg-bg .top-orderInfo {
    height: 85px;
    border-top: 1px solid rgba(0, 0, 0, 0.156);
    border-bottom: 1px solid rgba(0, 0, 0, 0.156);
    margin-bottom: 8px;
    padding: 12px 20px;
    color: #3b3737;
}

.rOrderdetail-dlg-bg .topleftblue {
    background-color: var(--q-primary);
    width: 5px;
    height: 20px;
}

.rOrderdetail-dlg-bg .sel_cf {
    background-color: var(--q-primary);
    color: #FFFFFF;
}
</style>
  
  